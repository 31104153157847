.navbar-link {
	font-family: "Lato", sans-serif;
	font-size: 1.1rem;
	color: rgba(0, 0, 0, 0.65);
	/* margin: 0 1rem; */
	font-weight: 400;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 992px) {
	.navbar-brand {
		display: none;
	}
}

.navbar-link:hover {
	color: var(--pink);
}

.neomorphic-button {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	padding: 8px 16px;
	background-color: #f1f0ff;
	box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1), -4px -4px 8px rgba(255, 255, 255, 0.5);
	transition: box-shadow 0.3s ease-in-out;
	border: none;
}

.neomorphic-button:active {
	transition: box-shadow 0.1s ease-in;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 0px 10px rgba(255, 255, 255, 0.8);
}

.active {
	color: var(--pink);
	font-weight: 550;
}
