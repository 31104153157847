@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Lato:wght@400;700;900&display=swap");

:root {
	--pink: #fb2386;
	--red: #fa2546;
	--blue: #2e18c4;
	--off-navy-blue: #424f9a;
	--navy-blue: #151875;
	--sky-blue: #f1f0ff;
	--pantone-purple: #e2d1f6;
	--purple: #7e33e0;
	--off-purple: #9f63b4;
	--off-blue: #151875;
	--turquoise: #08c4fc;
	--yellow: #f5c479;
}
.heading.brand-name {
	font-size: 1.75rem;
}

.top-rounded {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
.bottom-rounded {
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

.heading {
	font-family: "Josefin Sans", sans-serif;
	font-weight: 700;
	color: var(--navy-blue);
	font-size: 2rem;
}

.text {
	font-family: "Lato", sans-serif;
	font-weight: 400;
}

.yellow-border-onHover:hover {
	border-bottom: 5px solid var(--yellow);
	transition: all 0.3s ease-in-out;
}

.floating-whatsapp {
	animation: appear 5s ease-in-out;
}
@keyframes appear {
	0% {
		bottom: -70px;
	}
	90% {
		bottom: -70px;
	}
	98% {
		bottom: 40px;
	}
	100% {
		bottom: 32px;
	}
}

#wot-badge0::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--sky-blue);
    z-index: -1;
    border-radius: 8px; /* Add this line to make corners rounded */
}
#wot-badge0 {
    position: relative;
    z-index: 1;
    border-radius: 8px; /* Add this line to make corners rounded */
}