@import "../../styles/global.css";
.banner {
	background-color: var(--purple);
	color: whitesmoke;
	font-weight: 500;
	padding: 0.5rem 0;
	text-decoration: none;
	padding-left: 10%;
}
@media only screen and (max-width: 600px) {
	.banner {
		font-size: small;
	}
}
